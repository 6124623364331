








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation as VueValidation } from 'vuelidate';

import Validation from '../../../lib/support/validation/Validation';
import Alert from '@/shared/resources/components/Alert.vue';

@Component({
  components: {
    Alert,
  },
})
export default class FormValidationAlert extends Vue {
  /**
   * Props
   */
  @Prop() private validation?: VueValidation;

  /**
   * Decide to display errors
   */
  private get displayAlert(): boolean {
    if (!this.validation) {
      return false;
    }

    return this.validation.$dirty && !!this.validationErrors.length;
  }

  /**
   * Get validation errors
   */
  private get validationErrors(): string[] {
    if (!this.validation) {
      return [];
    }

    return Validation.translateInvalid(this.validation);
  }

  /**
   * Get errors string
   */
  private get errorsString(): string | null {
    if (!this.displayAlert) {
      return null;
    }

    return this.validationErrors.join('. ');
  }
}
